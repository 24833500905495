<!-- 账单明细页弹窗 -->
<template>
  <el-dialog
      title="账单明细"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @close="close"
      top="5vh"
      append-to-body
      width="80%"
  >
    <billContent
        ref="billContent"
        :parentBillId="billNo"
        v-if="dialogVisible"
    ></billContent>
  </el-dialog>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import billContent from 'views/finance/billContent.vue'

export default {
  data () {
    return {
      dialogVisible: false,
      billNo: null
    }
  },
  components: {
    billContent
  },
  methods: {
    close () {
      this.dialogVisible = false
    },
    show (billNo) {
      this.billNo = billNo
      this.$nextTick(() => {
        this.dialogVisible = true
      })
    }
  },
  mounted () {
  }
}
</script>
