<!-- 关联合同账单 -->
<template>
  <el-dialog
      title="关联合同账单"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @close="close"
      top="5vh"
      width="90%"
  >
    <div class="grid grid-cols-2 gap-14">
      <div class="" style="height: 80vh">
        <div class="title font-bold text-lg mb-4">已关联的账单</div>
        <AppTable
            ref="AppTable"
            :custom-table-data="billList"
            :tableColumns="isBindTableColumns"
            :refresh-btn="false"
        >
          <template #customerName="{ row }">
            <p class="truncate">
              {{ row.customerName }}
            </p>
          </template>
          <template #operator="{ row, $index }">
            <el-button size="small" @click="removeBillList(row, $index)"
            >移除
            </el-button>
          </template>
          <template #billNo="{ row }">
            <p
                class="cursor-pointer underline truncate"
                @click="showBillDetails(row)"
            >
              {{ row.billNo }}
            </p>
          </template>
        </AppTable>
      </div>
      <div class="" style="height: 80vh">
        <div class="title font-bold text-lg mb-4">未关联的账单</div>
        <AppTable
            ref="notBindAgreementAppTable"
            controller="/agreement/bill"
            :tableColumns="tableColumns"
            :request-params="requestParams"
        >
          <template #operator="{ row }">
            <el-button size="small" @click="pushBillList(row)">关联</el-button>
          </template>
          <template #offDtm="{ row }">
            {{ row.offDtm }}
          </template>
          <template #billNo="{ row }">
            <p
                class="cursor-pointer underline truncate"
                @click="showBillDetails(row)"
            >
              {{ row.billNo }}
            </p>
          </template>
          <template #customerName="{ row }">
            <p class="truncate">
              {{ row.customerName }}
            </p>
          </template>
        </AppTable>
      </div>
    </div>
    <billContentDialog ref="billContentDialog"></billContentDialog>
  </el-dialog>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import billContentDialog from 'components/bill/billContentDialog.vue'

export default {
  data () {
    return {
      dialogVisible: false,
      billList: [],
      agreementObj: {},
      requestParams: {
        states: 3,
        customerId: ''
      },
      isBindTableColumns: [
        {
          label: '客户名称',
          prop: 'customerId',
          type: 'customSlot',
          slotName: 'customerName'
        },
        {
          label: '账单编号',
          prop: 'billNo',
          width: '180px',
          type: 'customSlot',
          slotName: 'billNo'
        },
        {
          label: '账单金额',
          prop: 'totalFee',
          width: '100px'
        },
        {
          label: '出账人',
          prop: 'offName',
          width: '150px'
        },
        {
          label: '出账时间',
          prop: 'offDtm',
          width: '150px'
        },
        {
          label: '#',
          type: 'customSlot',
          slotName: 'operator'
        }
      ],
      tableColumns: [
        {
          label: '客户名称',
          prop: 'customerId',
          type: 'customSlot',
          slotName: 'customerName',
          filterType: 'headerFilterRadio',
          filtersList: []
        },
        {
          label: '账单编号',
          prop: 'billNo',
          width: '180px',
          search: true,
          type: 'customSlot',
          slotName: 'billNo'
        },
        {
          label: '账单金额',
          prop: 'totalFee',
          width: '100px'
        },
        {
          label: '出账人',
          prop: 'offName',
          width: '150px'
        },
        {
          label: '出账时间',
          prop: 'offDtm',
          type: 'customSlot',
          slotName: 'offDtm',
          filterType: 'headerFilterDatePicker',
          datePickerObj: [],
          datePickerKey: [
            'startOffDtm',
            'endOffDtm'
          ],
          width: '150px'
        },
        {
          label: '#',
          type: 'customSlot',
          slotName: 'operator'
        }
      ]
    }
  },
  components: {
    billContentDialog
  },
  methods: {
    close () {
      this.dialogVisible = false
      this.$emit('on-close')
    },
    /**
     *
     */
    show (agreementObj) {
      if (agreementObj.billList) {
        this.billList = JSON.parse(JSON.stringify(agreementObj.billList))
      } else {
        this.billList = []
      }
      this.agreementObj = JSON.parse(JSON.stringify(agreementObj))
      this.getClientList()
      this.dialogVisible = true
    },
    removeBillList (item, index) {
      this.$confirm('是否将该账单从合同移除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const tmp = JSON.parse(JSON.stringify(this.billList))
        tmp.splice(index, 1)
        await this.saveData(tmp)
        this.billList.splice(index, 1)
      })
    },
    pushBillList (item) {
      this.$confirm('是否将该账单关联入合同中?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const tmp = JSON.parse(JSON.stringify(this.billList))
        tmp.push(item)
        await this.saveData(tmp)
        this.billList.push(item)
      })
    },
    saveData (tmpBillList) {
      return new Promise(resolve => {
        const billNoList = tmpBillList ? tmpBillList.map(item => item.billNo) : []
        this.$api.save('agreement', {
          ...this.agreementObj,
          billNoList
        }).then(() => {
          this.$refs.notBindAgreementAppTable.reGetData()
          resolve()
        })
      })
    },
    getClientList () {
      this.$api.page('customerInfo', {
        size: 999
      }).then(res => {
        const filterList = res.data.content.map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
        filterList.unshift({
          label: '全部',
          value: ''
        })
        this.tableColumns.find(item => item.prop === 'customerId').filtersList = filterList
      })
    },
    showBillDetails (row) {
      this.$refs.billContentDialog.show(row.id)
    }
  },
  mounted () {
  }
}
</script>
