<!-- 合同详情页 -->
<template>
  <div>
    <blockTitle title="合同详情页"></blockTitle>
    <el-card shadow="never">
      <div class="card-content">
        <div
            class="text-xl font-bold pt-2 pb-4 border-b mb-4 flex justify-between"
        >
          <div>《{{ agreementObj.agreementName }}》 合同明细</div>
          <div>
            <el-button @click="editAgreement" size="small">编辑合同</el-button>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-4">
          <div>
            <div
                class="text-lg font-bold mb-2 border-b border-gray-200 border-dashed pb-2"
            >
              合同基础信息
            </div>
            <el-form
                ref="form"
                :model="agreementObj"
                label-width="160px"
                label-position="left"
                size="small"
                cass="overflow-auto"
            >
              <el-form-item label="合同名称">
                <div>
                  {{ agreementObj.agreementName }}
                </div>
              </el-form-item>
              <el-form-item label="合同编号">
                <div>
                  {{ agreementObj.agreementNo }}
                </div>
              </el-form-item>
              <el-form-item label="客户名称">
                <div>
                  {{ agreementObj.customerName }}
                </div>
              </el-form-item>
              <el-form-item label="授权数量">
                <div>{{ agreementObj.authNum }} 台</div>
              </el-form-item>
              <el-form-item label="合同金额">
                <div>{{ agreementObj.money }} 元</div>
              </el-form-item>
              <el-form-item label="商务经理">
                <div>
                  {{ agreementObj.businessHandle }}
                </div>
              </el-form-item>
              <el-form-item label="合同生效时间">
                <div>
                  {{ agreementObj.agreementDtm }}
                </div>
              </el-form-item>
              <el-form-item label="合同附件" v-if="agreementObj.agreementFile">
                <div>
                  <a class="" :href="agreementObj.agreementFile" target="_blank"
                  >点击查看文件</a
                  >
                </div>
              </el-form-item>
              <el-form-item label="创建时间">
                <div>
                  {{ agreementObj.createDtm }}
                </div>
              </el-form-item>
            </el-form>
          </div>
          <div>
            <div
                class="text-lg font-bold mb-2 border-b border-gray-200 border-dashed pb-2 flex justify-between"
            >
              <div>资源使用情况</div>
              <div>
                <span class="text-xs text-gray-500"
                >总数量：{{ licenseBillProject.quantity }}</span
                >
                <span class="text-xs text-gray-500 ml-2"
                >已使用：{{ licenseBillProject.assignNum }}</span
                >
                <span class="text-xs text-gray-500 ml-2"
                >剩余可用数量：{{ licenseBillProject.remainNum }}</span
                >
              </div>
            </div>
            <div>
              <div class="h-80 overflow-y-auto mt-4">
                <div
                    class="license-item my-4"
                    v-for="(item, index) in licenseBillProjectList"
                    :key="index"
                >
                  <div class="title flex justify-between">
                    <div class="text text-gray-700 text-sm font-bold">
                      {{ item.projectName }}
                    </div>
                  </div>
                  <div>
                    <span class="text-xs text-gray-500"
                    >总数量：{{ item.quantity }}</span
                    >
                    <span class="text-xs text-gray-500 ml-2"
                    >已使用：{{ item.assignNum }}</span
                    >
                    <span class="text-xs text-gray-500 ml-2"
                    >剩余可用数量：{{ item.quantity - item.assignNum }}</span
                    >
                  </div>
                  <el-progress
                      :show-text="false"
                      class="mt-1"
                      :percentage="(item.assignNum / item.quantity) * 100"
                  ></el-progress>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-card>

    <el-card shadow="never" class="mt-8">
      <div class="card-content">
        <div
            class="text-lg font-bold mb-2 border-b border-gray-200 border-dashed pb-2 flex justify-between"
        >
          <div class="">合同账单内容</div>
          <div>
            <el-button
                type="primary"
                size="small"
                @click="showLicenseBillProjectListDialog"
            >关联账单
            </el-button>
          </div>
        </div>
        <el-table :data="agreementObj.billList" style="width: 100%">
          <el-table-column prop="agUserName" label="客户名称">
            <template v-slot="{ row }">
              <div>{{ row.customerName }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="billNo" label="账单编号">
            <template v-slot="{ row }">
              <p
                  class="cursor-pointer underline truncate"
                  @click="showBillDetails(row)"
              >
                {{ row.billNo }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="money" label="账单金额">
            <template v-slot="{ row }">
              <div>{{ row.totalFee }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="offUser" label="出账人">
            <template v-slot="{ row }">
              <div>{{ row.offName }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="offDtm" label="出账时间">
            <template v-slot="{ row }">
              <div>{{ row.offDtm }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
    <licenseBillProjectListDialog
        @on-close="licenseBillProjectListDialogOnClose"
        ref="licenseBillProjectListDialog"
    ></licenseBillProjectListDialog>
    <billContentDialog ref="billContentDialog"></billContentDialog>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import licenseBillProjectListDialog from 'components/finance/licenseBillProjectListDialog.vue'
import billContentDialog from 'components/bill/billContentDialog.vue'

export default {
  data () {
    return {
      agreementObj: {
        billList: [],
        licenseBillProject: {}
      }
    }
  },
  components: {
    licenseBillProjectListDialog,
    billContentDialog
  },
  computed: {
    licenseBillProject () {
      return this.agreementObj.licenseBillProject || {
        quantity: 0,
        remainNum: 0,
        licenseBillProjectList: []
      }
    },
    licenseBillProjectList () {
      // console.log('this.agreementObj.licenseBillProject.licenseBillProjectList', this.agreementObj.licenseBillProject.licenseBillProjectList)
      return this.licenseBillProject.licenseBillProjectList || []
    }
  },
  methods: {
    getData () {
      this.$api.select('agreement', this.$route.query.id).then(res => {
        this.agreementObj = {
          ...res.data,
          licenseBillProject: res.data.licenseBillProject
        }
      })
    },
    editAgreement () {
      this.$router.push({
        name: 'agreementEdit',
        query: { id: this.agreementObj.id }
      })
    },
    showLicenseBillProjectListDialog () {
      this.$refs.licenseBillProjectListDialog.show(this.agreementObj)
    },
    licenseBillProjectListDialogOnClose () {
      this.getData()
    },
    showBillDetails (row) {
      this.$refs.billContentDialog.show(row.id)
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
